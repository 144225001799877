import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout/Layout";
import { siteTitle } from "../../data/SiteConfig";
import headerCons from "../images/consumer/headerCons.svg";
import { navigate } from "gatsby";
import Money from "../images/icons/Money.svg";
import Approval from "../images/icons/Approval.svg";
import Trust from "../images/icons/Trust.svg";
import Hand from "../images/icons/Hand.svg";
import Experian from "../images/icons/Experian.svg";

const footerItems = [
  {
    logo: {
      src: Money,
      alt: "Money",
    },
    caption: "1 Application - > 50 + Lenders",
  },
  {
    logo: {
      src: Approval,
      alt: "Approval",
    },
    caption: "Instant Approval",
  },
  {
    logo: {
      src: Trust,
      alt: "Trust",
    },
    caption: "Trusted by 1,00,000+ Customers",
  },
  {
    logo: {
      src: Hand,
      alt: "Hand",
    },
    caption: "500 + Cr disbursed",
  },
  {
    logo: {
      src: Experian,
      alt: "Experian",
      className: "h-8",
    },
  },
];

const consent = () => {
  return (
    <Layout>
      <div className="homepage">
        <Helmet title={`Consumer consent | ${siteTitle}`}>
          <link
            rel="icon"
            href="https://fincity-public.s3.ap-south-1.amazonaws.com/website/com/images/fincity-logo.png"
            type="image/icon type"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
        </Helmet>
        <div className=" relative flex flex-wrap flex-col md:min-h-screen  items-end ">
          {/* body */}
          <div className="max-w-6xl p-5 mx-auto my-10">
            <a target="_blank" href="https://fincity.com">
              <div className="cursor-pointer">
                <img src={headerCons} alt={"header"} />
              </div>
            </a>
            <h1 className="text-3xl font-semibold tracking-four_px my-10">
              Consent
            </h1>
            <p className="text-sm font-normal">
              I appoint and allow FINCITY to access my credit information from
              Credit Information Companies in order to understand my
              creditworthiness and thereinafter provide personalized values
              which would help them generate the Credit Information Report,
              Credit Score and Credit Analysis{" "}
              <span className="text-base font-medium">(“Aggregates”)</span> .
              <br />
              <br />
              <span className="text-base font-medium">
                Information Collection, Use, Confidentiality, No-Disclosure and
                Data Purging
              </span>
              <br />
              <br />
              <span className="underline italic">FINCITY</span> can only access
              your Credit Information Aggregates as your authorized
              representative and shall use the Credit Information Aggregates for
              limited end use purpose consisting of and in relation to the
              services proposed to be availed by you from us on the Website. We
              shall not aggregate, retain, store, copy, reproduce, republish,
              upload, post, transmit, sell or rent the Credit Information
              Aggregates to any other person and the same cannot be copied or
              reproduced other than as agreed herein. The Parties acknowledge
              that the Credit Information Aggregates provided to
              <span className="underline italic"> FINCITY</span> is solely the
              property of Experian and the consumer (as appropriate). We shall
              protect and keep confidential the Credit Information Aggregates
              both online and offline and use best in class security measures in
              line with applicable laws. The Credit Information Aggregates
              shared by You, or received on your behalf shall be destroyed,
              purged, erased or returned to Experian promptly either on the
              customer seeks FINCITY expressly to do so and/or seeks to revoke
              the consent OR if it is mandated or intimated by regulators. The
              relationship between you and the Parties shall be governed by laws
              of India and all claims or disputes arising there from shall be
              subject to the exclusive jurisdiction of the courts of India.{" "}
              <br />
              <br />
              You hereby consent to FINCITY being appointed as your authorised
              representative to receive your Credit Information from Experian
              for the purpose of evaluating the credit worthiness of
              consumers/customers for facilitating the provision of housing
              development finance for real estate developers, home loans and/or
              loans against property for the general public (“End Use Purpose”).
              <br />
              <br />
              <span className="text-base font-medium">Definitions:</span>
              <br />
              <br />
              Capitalised terms used herein but not defined above shall have the
              following meanings:
              <br />
              <br />
              <span className="text-base font-medium">
                “Credit Information”
              </span>{" "}
              means the aggregates such as credit information report/ scores/
              aggregates / variables / inferences which shall be generated by
              Experian;
              <br />
              <br /> <span className="text-base font-medium">
                {" "}
                “CICRA”{" "}
              </span>{" "}
              shall mean the Credit Information Companies (Regulation) Act, 2005
              read with related rules and regulations. <br />
              <br />
              <span className="text-base font-medium">“Experian”</span> shall
              mean Experian Credit Information Company of India Private Limited
            </p>
          </div>
          {/* Footer */}
          <div className="flex-initial flex flex-col  md:flex-row items-center justify-around w-full py-4 px-2 text-sm text-center md:text-left bg-teal">
            {footerItems.map(({ logo, caption }) => (
              <div
                key={logo.src + logo.alt}
                className={`flex flex-row justify-center items-center my-2 md:my-0${
                  caption ? " mx-2" : " mx-2"
                }`}
              >
                <img
                  className={`pr-2${logo.className ? logo.className : " w-8"}`}
                  src={logo.src}
                  alt={logo.alt}
                />
                {caption && (
                  <p className="text-base md:text-sm text-left text-white">
                    {caption}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default consent;
